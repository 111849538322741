import React, { useState } from "react";
import "./Login.css";
import LoginAPI from "../../api/Auth/LoginAPI";
import errorImage from "../../images/404.png";

const Login = () => {
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loginLoading, setLoginLoading] = useState(false);
  const LogIn = () => {
    // Trim white spaces from inputs
    const trimmedPassword = password.trim();

    // Validation checks
    if (!trimmedPassword) {
      setError("يرجى إدخال كلمة المرور بدون مسافات إضافية");
      return;
    }
    if (trimmedPassword.length < 3) {
      setError("كلمة المرور يجب أن تحتوي على 3 أحرف على الأقل");
      return;
    }

    // Clear error and proceed with API call
    setError("");
    const data = {
      password: trimmedPassword.toLowerCase(),
      role: "Student",
    };

    setLoginLoading(true);
    LoginAPI(data, setError, setLoginLoading);
  };

  return (
    <>
      <div className="errorPage">
        <div className="errorPage_container">
          <div className="error_404">
            <h1>404</h1>
            <img src={errorImage} alt="error 404" />
          </div>
          <p>هذه المنصة لا تعمل الا علي شاشة الهاتف</p>
          <p>يرجي استخدام الهاتف لعدم التعرض الي ضياع الحساب</p>
          <span>Dr: Python</span>
        </div>
      </div>
      <div className="view_content">
        <section className="login">
          <div className="login_container">
            <div className="login_form">
              <h3>تسجيل دخول</h3>
              <input
                type="text"
                placeholder="الباسورد"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onBlur={() => setPassword(password.trim())} // Trim on blur
                dir="ltr"
              />
              {error && <p className="error_message">{error}</p>}
              <button onClick={LogIn}>
                {loginLoading ? <span className="loader"></span> : "تسجيل"}
              </button>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Login;

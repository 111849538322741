import './App.css';
import Home from './components/Home/Home';
import Login from './components/Login/Login';
import Subjects from './components/Subjects/Subjects';
import AllLectures from './components/AllLectures/AllLectures';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Test from './components/test/Test';
import UnsupportedDevice from './components/UnsupportedDevice/UnsupportedDevice'; // For unsupported devices
import UnsupportedBrowser from './components/UnsupportedBrowser/UnsupportedBrowser'; // For unsupported browsers
import { useEffect, useState } from "react";

function App() {
  const isAuth = localStorage.getItem('USER_TOKEN');

  // // Check for mobile devices
  // const isMobileDevice = /Mobile|Android|iPhone|iPad/i.test(navigator.userAgent) && window.innerWidth <= 1500;

  // // Detect Kiwi Browser across all devices
  // const isKiwiBrowser = /Kiwi/i.test(navigator.userAgent);

  // if (isKiwiBrowser) {
  //   return <UnsupportedBrowser />;
  // }


  // if (!isMobileDevice) {
  //   return <UnsupportedDevice />;
  // }

  // document.onkeydown = (e) => {
  //   if (e.key == 123) e.preventDefault();
  //   if (e.ctrlKey && e.shiftKey && ['I', 'C', 'J'].includes(e.key.toUpperCase())) e.preventDefault();
  //   if (e.ctrlKey && e.key.toUpperCase() == 'U') e.preventDefault();
  // };

  // document.addEventListener('contextmenu', (e) => e.preventDefault());

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={isAuth ? <Navigate to="/subjects" /> : <Login />} />
          <Route path="/lectures/:subjectId/:lectureId/:token" element={isAuth ? <Home /> : <Navigate to="/" />} />
          <Route path="/subjects" element={isAuth ? <Subjects /> : <Navigate to="/" />} />
          <Route path="/all_lectures/:subjectId" element={isAuth ? <AllLectures /> : <Navigate to="/" />} />
          <Route path="/test" element={isAuth ? <Test /> : <Navigate to="/" />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
